import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, Observer } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { CookieService } from 'ngx-cookie-service';

import { CognitoCallback, CognitoUtil } from './cognito.service';
import { AuthenticationDetails, CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk/global';
import * as STS from 'aws-sdk/clients/sts';

import { environment } from '../../../../environments/environment';
import { User } from '../../models/user.model';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class UserLoginService {
    public static env = environment.production;

    mfaStep = false;
    mfaData = {
        destination: '',
        callback: null
    };

    constructor(
        public cognitoUtil: CognitoUtil,
        private router: Router,
        private http: HttpClient,
        private cookieService: CookieService) {
    }

    public async authenticate(callback: any, username: string, password: string) {
        const authenticationData = {
            Username: username,
            Password: password,
        };
        const authenticationDetails = new AuthenticationDetails(authenticationData);

        const userData = {
            Username: username,
            Pool: this.cognitoUtil.getUserPool()
        };

        const cognitoUser = new CognitoUser(userData);
        await cognitoUser.authenticateUser(authenticationDetails, {
            newPasswordRequired: (userAttributes, requiredAttributes) => {
            },
            onSuccess: result => {
                // confirm with backend
                this.validateUser(result.getIdToken());
                callback.cognitoCallback(null, null);
            },
            onFailure: err => {
                callback.cognitoCallback('', null);
            }
        });
    }

    public logout() {
        const cogUser = this.cognitoUtil.getCurrentUser();
        if (cogUser) {
            this.cognitoUtil.getCurrentUser().signOut();
        }
        this.cookieService.delete('tok');
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('fullName');
        localStorage.removeItem('roles');
        localStorage.removeItem('bearer');
        localStorage.removeItem('userName');
        localStorage.removeItem('employeeSigned');
        document.location.href = "/";
    }

    public isAuthenticated(option?: any) {
        const cognitoUser = this.cognitoUtil.getCurrentUser();
        if (cognitoUser != null) {
            cognitoUser.getSession((err, session) => {
                if (err) {
                    this.router.navigate(['login']);
                }

                this.validateUser(session.getIdToken(), option);
            });
        } else {
            localStorage.removeItem('isLoggedIn');
            if (this.router.url !== '/login') {
                this.router.navigate(['login']);
            }
        }
    }

    public validateUser(session: any, option?: any) {
        if (session && session.payload.email) {
            this.cookieService.set('tok', session.payload.email, null, null, null, true, 'Strict');
            localStorage.setItem('isLoggedIn', 'true');
            if (session.jwtToken) {
                localStorage.setItem('bearer', session.jwtToken);
            }
            if (session.payload['cognito:groups']) {
                localStorage.setItem('roles', JSON.stringify(session.payload['cognito:groups']));
            }

            if (option) {
                this.router.navigate(['/']);
            }
        } else {
            localStorage.removeItem('isLoggedIn');
        }
    }

    private route(path: any) {
        if (path) {
            this.router.navigate([path]);
        }
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error); // log to console instead
            return of(result as T);
        };
    }

    private log(message: string) {
        if (!UserLoginService.env) {
            console.log(message);
        }
    }

    public forgotPassword(username: string, callback: CognitoCallback) {
        const userData = {
            Username: username,
            Pool: this.cognitoUtil.getUserPool()
        };

        const cognitoUser = new CognitoUser(userData);

        cognitoUser.forgotPassword({
            onSuccess: (dat) => {
            },
            onFailure: (err) => {
                callback.cognitoCallback('exeed', err.message);
            },
            inputVerificationCode: (data) => {
                if (data) {
                    callback.cognitoCallback('vcode', data.CodeDeliveryDetails.Destination);
                }
            }
        });
    }

    public confirmNewPassword(email: string, verificationCode: string, password: string, callback: CognitoCallback) {
        const userData = {
            Username: email,
            Pool: this.cognitoUtil.getUserPool()
        };

        const cognitoUser = new CognitoUser(userData);

        cognitoUser.confirmPassword(verificationCode, password, {
            onSuccess: () => {
                callback.cognitoCallback('success', null);
            },
            onFailure: (err) => {
                callback.cognitoCallback(err.message, null);
            }
        });
    }

    private onLoginSuccess = (callback: CognitoCallback, session: CognitoUserSession) => {
        AWS.config.credentials = this.cognitoUtil.buildCognitoCreds(session.getIdToken().getJwtToken());

        // So, when CognitoIdentity authenticates a user, it doesn't actually hand us the IdentityID,
        // used by many of our other handlers. This is handled by some sly underhanded calls to AWS Cognito
        // API's by the SDK itself, automatically when the first AWS SDK request is made that requires our
        // security credentials. The identity is then injected directly into the credentials object.
        // If the first SDK call we make wants to use our IdentityID, we have a
        // chicken and egg problem on our hands. We resolve this problem by 'priming' the AWS SDK by calling a
        // very innocuous API call that forces this behavior.
        const clientParams: any = {};

        const sts = new STS(clientParams);
        sts.getCallerIdentity((err, data) => {
            callback.cognitoCallback(null, session);
        });
    }

    private onLoginError = (callback: CognitoCallback, err) => {
        callback.cognitoCallback(err.message, null);
    }
}
