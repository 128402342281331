import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { ErrorDialogService } from './error-dialog.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    private ngZone: NgZone;
    constructor(
    private errorDialogService: ErrorDialogService,
    private zone: NgZone
    ) {
        this.ngZone = zone;
    }

    handleError(error: any) {
        let locError = error;
        let showDialog = false;
        if (!(error instanceof HttpErrorResponse)) {
            if (error.rejection) {
                locError = error.rejection;
                if (!locError.message.includes('TractionTool')) {
                    showDialog = true;
                }
            }
        }

        if (showDialog) { 
            this.ngZone.run(() =>
                this.errorDialogService.openDialog(
                    locError.message || 'Undefined client error',
                    (locError.status) ? locError.status : locError.name
                )
            );
        }

        console.log('Error from global error handler', error);
    }
}
