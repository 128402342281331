import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UserLoginService } from './shared/services/aws/user.login.service';
import { CognitoUtil } from './shared/services/aws/cognito.service';
import { AwsUtil } from './shared/services/aws/aws.service';
import { UserRegistrationService } from './shared/services/aws/user.registration.service';
import { NgwWowModule } from 'ngx-wow';
import { ToastrModule } from 'ngx-toastr';
import { RequestInterceptor } from './shared/request.interceptor';
import { NotFoundModule } from './not-found/not-found.module';
import { GlobalErrorHandler } from './shared/error/globalErrorHandler';
import { ErrorDialogService } from './shared/error/error-dialog.service';
import { LoadingDialogService } from './shared/error/loading-dialog.service';
import { MboErrorModule } from './shared/error/mbo.error.module';

// AoT requires an exported function for factories
export const createTranslateLoader = (http: HttpClient) => {
    /* for development
    return new TranslateHttpLoader(
        http,
        '/start-javascript/sb-admin-material/master/dist/assets/i18n/',
        '.json'
    );*/
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};


@NgModule({
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut: 3500,
            positionClass: 'toast-top-right',
            preventDuplicates: true
          }),
        LayoutModule,
        OverlayModule,
        HttpClientModule,
        BrowserModule,
        NgwWowModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        NotFoundModule,
        MboErrorModule
    ],
    providers: [
        UserLoginService,
        CognitoUtil,
        AwsUtil,
        CookieService,
        UserRegistrationService,
        ErrorDialogService,
        LoadingDialogService,
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    ],
    bootstrap: [AppComponent],
    declarations: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
