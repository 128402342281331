import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { UserLoginService } from '../../shared/services/aws/user.login.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private userLoginService: UserLoginService,
        private cookieService: CookieService) {}

    canActivate() {
        if (localStorage.getItem('isLoggedIn')) {
            if (!this.cookieService.check('tok')) {
                this.userLoginService.isAuthenticated();
                //console.log('Cookie not set');
            }
            return true;
        }

        this.router.navigate(['/login']);
        return false;
    }
}
