import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoadingDialogService } from '../shared/error/loading-dialog.service';
import { finalize } from 'rxjs/operators';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private loadingDialogService: LoadingDialogService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
    ): Observable<HttpEvent<any>> {
      this.loadingDialogService.openDialog();

      if (!req.url.includes('apps.nearshoretc.com')) {
        const bearer = localStorage.getItem('bearer');
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${bearer}`,
          'Content-Type': 'plain/text',
          'Accept': 'plain/text, application/json'
        });
        const httpReq = req.clone({
          headers: headers
        })  ;
        return next.handle(httpReq).pipe(
          finalize(() => {
            this.loadingDialogService.hideDialog();
          })
        );
      } else {
        const headers = new HttpHeaders({
          'Accept': 'application/json'
        });
        const httpReq = req.clone({
          headers: headers
        })  ;
        return next.handle(httpReq).pipe(
          finalize(() => {
            this.loadingDialogService.hideDialog();
          })
        );
      }
    }
  }
